import {_request, _modal} from '../functions';

export class send_form {
    constructor(el) {
        if (!this.setVars(el)) return;
        this.setEvents();
    }

    setVars(el) {
        this.form = typeof el === 'string' ? document.body.querySelector(el) : el;
        if (!this.form) return false;

        this.inputs = this.form.querySelectorAll('input:not([type="checkbox"]),textarea,select,input[type="checkbox"].req');
        if (!this.inputs.length) return false;


        this.error_type = this.form.querySelector('[name="error_type"]');

        this.input_file = this.form.querySelector('input[name="file"]');

        this.btn = this.form.getElementsByTagName('button')[0];

        this.obj = {
            post: {},
            reCaptcha: false,
            arr: []
        };

        window.is_recaptcha = false;

        return true;
    }

    setEvents() {
        let min, info;

        this.input_file && this.input_file.addEventListener('change', (e) => {
            info = e.target.parentNode.querySelector('[data-info]');
            info && del(info);
            render(e.target.parentNode, `<p class="row txt-center" data-info>Wgrano plik: ${e.target.files[0].name}</p>`)
        });

        if (this.error_type) {
            this.checkErrorTypeIndex(this.error_type.selectedIndex);

            this.error_type.addEventListener('change', (e) => {
                this.checkErrorTypeIndex(this.error_type.selectedIndex);
            })
        }

        each(this.inputs, (key, val) => {
            val.addEventListener('change', (e) => {
                min = parseInt(e.target.dataset.min) || 0;
                if (e.target.value.length >= min) e.target.classList.remove('error'), e.target.parentNode.classList.remove('error');
            })
        });

        window._validate = (response) => {
            _request('api/recaptcha/', JSON.stringify({
                response: response
            }), {
                cb: (xhr) => {
                    xhr.json().then(json => {
                        if (json.success) {
                            this.obj.reCaptcha = true;
                            window.is_recaptcha = true;
                        }
                    });
                }
            });
        };

        addJS('https://www.google.com/recaptcha/api.js', {
            async: true, defer: true, cb: () => {
                console.log('loaded API');
                this.submitForm();
            }
        });
    }

    checkErrorTypeIndex(key) {
        switch (key) {
            case 8:
            case 7:
                this.error_type.parentNode.nextElementSibling.classList.remove('hide')
                this.error_type.parentNode.nextElementSibling.firstElementChild.value = '';
                break;
            default:
                this.error_type.parentNode.nextElementSibling.classList.add('hide')
                this.error_type.parentNode.nextElementSibling.firstElementChild.value = 'brak opisu';
        }
    }

    submitForm() {
        this.form.addEventListener('submit', (e) => {
            e.preventDefault();
            this.obj.arr = [];

            this.validateForm();

            if (this.obj.arr.length) return _modal('Uzupełnij wymagane pola.', {color: 'red'});
            if (!window.is_recaptcha) return _modal('Nie udało się zweryfikować reCaptcha,<br>Spróbuj ponownie.', {
                color: 'alert', cb: () => {
                    try {
                        grecaptcha.reset();
                    } catch (e) {
                    }
                }
            });

            clearDom(this.form);
            this.obj.pre = render(this.form, `<img class="auto" src="img/l.png" alt="loading..">`);

            _request(`${this.form.action}`, this.obj.data, {
                type: 'multipart',
                cb: (response) => {
                    response.json().then(resp => {
                        del(this.obj.pre);
                        this.form.reset();
                        render(this.form, `<p style="font-size:24px">${resp.status ? 'Dziękujemy, formularz został wysłany.' : 'Błąd - nie można wysłać formularza.'}</p>`);
                        if (resp.url) window.location.href = resp.url;
                        if(resp.msg) _modal(`${resp.msg}`);
                    })
                }
            });
        });
    }

    validateForm() {
        let min;

        this.obj.data = new FormData();
        each(this.inputs, (key, val) => {
            min = val.dataset.min || 0;

            switch (val.type) {
                case 'checkbox':
                    if (!val.checked) {
                        val.classList.add('error');
                        val.parentNode.classList.add('error');
                        this.obj.arr.push(val);
                    } else {
                        val.classList.remove('error');
                        val.parentNode.classList.remove('error');
                        this.obj.data.append(val.name, val.type === 'file' ? val.files[0] : val.value);
                        this.obj.post[val.name] = val.value;
                    }
                    break;
                case 'email':
                    if (!is_email(val.value)) {
                        val.classList.add('error');
                        val.parentNode.classList.add('error');
                        this.obj.arr.push(val);
                    } else {
                        val.classList.remove('error');
                        val.parentNode.classList.remove('error');
                        this.obj.data.append(val.name, val.type === 'file' ? val.files[0] : val.value);
                        this.obj.post[val.name] = val.value;
                    }
                    break;
                default:
                    if (val.value.length < val.dataset.min) {
                        val.classList.add('error');
                        val.parentNode.classList.add('error');
                        this.obj.arr.push(val);
                    } else {
                        val.classList.remove('error');
                        val.parentNode.classList.remove('error');
                        this.obj.data.append(val.name, val.type === 'file' ? val.files[0] : val.value);
                        this.obj.post[val.name] = val.value;
                    }
            }
        });
    }
}
