import '../sass/core.scss';
import '../sass/framework/hd.scss';

import "./global";
import {lazyCSS} from "./class/lazyCSS";
import {lazyImg} from "./class/lazyImg";
import {observer} from "./class/observer";
import {carousel} from "./class/carousel";
import {send_form} from "./class/send_form";
import menu from "./class/menu";

const mod = {};

// ======== load core =========
mod.core = () => {
  mod.css = new lazyCSS();
  mod.lazy = new lazyImg('[data-url]');
  mod.menu = new menu();

  let top, isActive = false, header = document.body.getElementsByTagName('header')[0];
  header && window.addEventListener('scroll', (e) => {
    top = document.documentElement.scrollTop || document.body.scrollTop;
    if (top > 1 && !isActive) {
      header.style.height = '50px';
      isActive = !isActive;
    } else if (top === 0 && isActive) {
      header.style.height = '';
      isActive = !isActive;
    }
  }, {passive: true});

  const imageLg = document.body.getElementsByClassName('image-lg')[0],
    thumbs = document.body.getElementsByClassName('thumb');
  if (imageLg && thumbs.length) {
    let active;
    thumbs[0].classList.add('act');
    each(thumbs, (key, val) => {
      val.addEventListener('click', () => {
        active = document.body.querySelector('.thumb.act');
        imageLg.src = val.dataset.lg;
        active && active.classList.remove('act');
        val.classList.add('act');
      })
    })
  }

  mod.autocaro = new observer(document.body.getElementsByClassName('carousel'), {
    cb: (el) => {
      mod.img = new lazyImg(el.querySelectorAll('[data-url]'), {rootMargin: '-10px -10px', rwd: 680});
      switch (el.dataset.els) {
        case '4':
          mod.caro = new carousel({
            el: el,
            slides: 4,
            rwd: {
              1280: 4,
              1024: 3,
              680: 2,
              480: 1
            }
          });
          break;
        default:
          mod.caro = new carousel({
            el: el,
            slides: 1,
            rwd: {
              1280: 1
            }
          });
      }
    }
  });
  mod.contact = new send_form(document.body.querySelector('[data-contact]'));
  mod.service = new send_form(document.body.querySelector('[data-service]'));
  addCSS('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
  //!localStorage.getItem('cookie') && render(document.body, `<div style="padding:10px 0;font-size:15px;line-height:22px;border-bottom: 1px solid #D3D0D0; text-align: center; position: fixed; bottom: 0px; background-color: #e41224; color:#fff;width: 100%; z-index: 999; opacity: 0.97; font-family: Arial;">Aby w pełni skorzystać z możliwości serwisu, musisz zaakceptować ciasteczka (cookies).<button id="accept-cookies-checkbox" name="accept-cookies" style="background-color:#ffffff; font-size: 16px; padding: 5px 10px; color: #000; border:0;border-radius: 7px; -moz-border-radius: 7px; -webkit-border-radius: 7px; display: inline-block; margin-left: 10px; text-decoration: none; cursor: pointer;" onclick="del(this.parentNode);localStorage.setItem('cookie', 1)">Akceptuję</button> <a href="polityka-prywatnosci" name="polityka prywatności" style="background-color:#ffffff; font-size: 16px; padding: 5px 10px; color: #000; border-radius: 7px; -moz-border-radius: 7px; -webkit-border-radius: 7px; display: inline-block; margin-left: 10px; text-decoration: none; cursor: pointer;">Polityka prywatności</a></div>`);
};

!is_observer() ? addJS('js/polyfill.js', {
  cb: mod.core
}) : document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', mod.core) : mod.core();
