export function _request(url, data, cfg = {}) {
  let controller = new AbortController(), obj = Object.assign({
    signal: controller.signal,
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    //'Accept': 'application/json, application/xml, text/plain, text/html, application/pdf, image/jpeg, image/png, image/jpeg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    //'Content-Type': 'application/json', //multipart/form-data, application/json, application/x-www-form-urlencoded
    headers: {
      'Content-Type': typeof cfg.type !== 'undefined' ? cfg.type : 'application/json'
    },
    body: data,
    cb: null,
  }, cfg);

  obj.type && obj.type.indexOf('multipart') > -1 && delete obj.headers;

  fetch(url, obj).then(response => {
    response.status !== 200 && controller.abort();
    typeof obj.cb === 'function' && obj.cb(response);
  });
}

export function _modal(msg, cfg = {}) {
  const obj = Object.assign({
    key: 'msg',
    color: 'def',
    time: .3,
    type: 0,
    padding: '1em 1.5em 0',
    wrap: 'fix row h100 left0 top0 bg--pop',
    btn: 'OK',
    btnClass: 'btn w-auto',
    fontSize: '1em',
    align: 'left',
    width: '500px',
    clear: true,
    scrollable: true,
    cb: null
  }, cfg);

  loadlib('js/gsap.js', 'TweenLite', obj.fn = () => {
    let pop, clickEv = (e) => {
      e.preventDefault();
      if (typeof obj.cb === 'function') obj.cb();
      e.target.removeEventListener('click', clickEv);
      clickEv = null;
      del(pop);
    };

    TweenLite.fromTo(pop = render(document.body, `<div class="pop z1000 ${obj.wrap} flex loaded justify-center align-center"><div class="flex rel fw400 flex-wrap border bg--w shad justify-start lg txt-${obj.align} row contentBox" style="text-align:left;padding:${obj.padding};max-width:${obj.width}"><div class="row txt-center ${obj.scrollable ? 'scroll' : ''} c--${obj.color} row mt25 mb25"><div class="row" style="font-size:${obj.fontSize}">${msg}</div>${obj.btn.length ? `<button class="close btn--red mt20 mb20 ${obj.btnClass}" style="min-width:initial;font-size:15px">${obj.btn}</button>` : ''}</div></div></div>`, {key: obj.key}), obj.time, {autoAlpha: 0}, {autoAlpha: 1});
    each(pop.getElementsByClassName('close'), (key, val) => {
      val.onclick = clickEv;
    });

    return pop;
  });
}
