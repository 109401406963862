export class observer {
  constructor(el, config = {}) {
    if (!this.setVars(el)) return;
    this.setEvents(config);
  }

  setVars(el) {
    if(!el) return false;
    if (!Array.isArray(el)) this.tpl = [].slice.call(el);
    if (!this.tpl.length) return false;
    
    return true;
  }

  setEvents(config) {
    let index = 0, len = this.tpl.length;

    let observerIntersection = new IntersectionObserver(onIntersection, {
        root: config.root || null,
        rootMargin: config.rootMargin || '0px',
        threshold: config.threshold || [0.0]
      }
    );

    let i = 0;
    for (i; i < len; i++) observerIntersection.observe(this.tpl[i]);

    function onIntersection(els) {
      let i = 0;
      for (i; i < els.length; i++) if (els[i].intersectionRatio > 0) config.cb(els[i].target, index), index++, index === len ? observerIntersection.disconnect() : observerIntersection.unobserve(els[i].target);
    }
  }
}