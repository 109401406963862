class Menu {
    constructor() {
        if (!this.setVars()) return;
        this.setListeners();
    }

    setVars() {
        this.burger = document.body.getElementsByClassName('hamburger')[0];
        if (!this.burger) return false;

        this.nav = document.body.getElementsByClassName('menu__wrap')[0];
        if (!this.nav) return false;

        return true;
    }

    setListeners() {
        this.burger.addEventListener('click', (e) => {
            this.burger.classList.toggle('is-active');
            this.nav.classList.toggle('open');
        })
    }
}

export default Menu;
