export let carousel = function ({el, slides = 1, rwd = {1200: slides}}) {
  this.init(el, slides, rwd);
};

carousel.prototype = {
  constructor: carousel,

  init: function (el, slides, rwd) {
    if (!this._setVars(el) || this._slideCount < slides) return;
    loadlib('js/carousel.js', 'Siema', () => {
      this._setEvents(slides, rwd);
    })
  },

  _setVars: function (el) {
    let _this = this;

    _this._slider = el;
    if (!_this._slider) return;

    _this._slideCount = _this._slider.children.length;

    _this._dots = _this._slider.getAttribute('data-dots') || false;
    _this._index = _this._slider.getAttribute('data-index') || 'false';
    _this._sliderIndex = document.getElementById(_this._index);
    _this._autoPlay = _this._slider.getAttribute('data-autoPlay') || 'false';
    _this._hoverStop = _this._slider.getAttribute('data-hoverStop') || 'true';
    _this._time = _this._slider.getAttribute('data-time') || '3500';
    _this._prev = document.getElementById(_this._slider.dataset.prev);
    _this._next = document.getElementById(_this._slider.dataset.next);
    _this._anim = this._slider.dataset.anim || false;

    return true;
  },

  _setEvents: function (slides, rwd) {
    if(this._slideCount <= slides) {
      del(this._prev);
      del(this._next);
    }
    if(this._slideCount >= slides) {
      this._slider.classList.remove('flex');
      this.slider(slides, rwd);
    }
  },

  slider: function (slides, rwd) {
    let _this = this;

    _this.setBaseClasses();
    if (_this._dots) {
      _this.setPagination();
      _this.updatePagination();
    }
    _this.setHoverPause();
    _this.initSlide(slides, rwd);

  },

  // init carousel

  setBaseClasses: function () {
    Siema.prototype.setBaseClasses = function (clsList, clsItem, clsItemInner) {
      var _this = this, items, len, i;

      _this.sliderFrame.classList.add(clsList);
      items = _this.selector.querySelector('.' + clsList).children;
      len = items.length;

      for (i = 0; i < len; i++) {
        items[i].classList.add(clsItem);
        items[i].querySelector('div').classList.add(clsItemInner);
      }
    };
  },

  setPagination: function () {
    Siema.prototype.setPagination = function (clsPagination, clsPaginationBtn) {
      var _this = this, numOfSlides, len;
      len = _this.innerElements.length;

      if (len === 0) return;
      numOfSlides = 0;

      if (_this.perPage < len) {
        numOfSlides = (len - _this.perPage) + 1;
      }

      if (numOfSlides === 0) return;
      var pagination, btns, btn, siblingBtns, clsBtnActive, i;

      pagination = document.createElement('div');
      pagination.className = clsPagination;
      clsBtnActive = clsPaginationBtn + '--active';

      //if (numOfSlides === 0) return;

      for (i = 0; i < numOfSlides; i++) {
        btn = document.createElement('button');
        btn.setAttribute('type', 'button');
        btn.className = clsPaginationBtn;
        (function (i) {
          btn.addEventListener('click', function () {
            _this.goTo(i);
          });
        })(i);
        pagination.appendChild(btn);
      }

      _this.selector.appendChild(pagination);
      btns = pagination.querySelectorAll('.' + clsPaginationBtn);
      btns[_this.config.startIndex].classList.add(clsBtnActive);

      [].forEach.call(btns, function (btnItem) {
        btnItem.addEventListener('click', function () {
          btnItem.classList.add(clsBtnActive);
          siblingBtns = [].filter.call(btnItem.parentNode.children, function (child) {
            return child !== btnItem;
          });
          [].forEach.call(siblingBtns, function (siblingBtn) {
            siblingBtn.classList.remove(clsBtnActive);
          });
        });
      });
    };
  },

  updatePagination: function () {
    Siema.prototype.updatePagination = function (clsPaginationBtn) {
      var btns = this.selector.querySelectorAll('.' + clsPaginationBtn);
      if (!btns) return;
      var clsBtnActive = clsPaginationBtn + '--active';

      [].forEach.call(btns, function (btn) {
        btn.classList.remove(clsBtnActive);
      });
      btns[this.currentSlide].classList.add(clsBtnActive);
    };
  },

  setHoverPause: function () {
    var _this = this;

    Siema.prototype.setHoverPause = function (stopTime) {
      var that = this, timeout;

      function play() {
        if (_this._autoPlay === 'false') return;
        timeout = setInterval(function () {
          that.next();
        }, stopTime);
      }

      function stop() {
        clearInterval(timeout);
      }

      play();
      if (_this._hoverStop === 'true') {
        that.selector.addEventListener('mouseenter', stop);
        that.selector.addEventListener('mouseleave', play);
      }
    };
  },

  initSlide: function (slides, rwd) {
    'use strict';
    var _this = this, carousel, clsPrefix;

    each(this._slider.children, (key, val) =>{
      val.classList.remove('row');
      val.classList.remove('col-4');
    })

    clsPrefix = 'slider';

    carousel = new Siema({
      selector: _this._slider,
      duration: 600,
      easing: 'cubic-bezier(0.785, 0.135, 0.15, 0.86)',
      perPage: rwd,
      startIndex: 0,
      draggable: true,
      multipleDrag: true,
      threshold: 20,
      loop: true,
      onInit: function () {
        if (_this._sliderIndex) _this._sliderIndex.innerHTML = this.currentSlide + 1 + ' / ' + _this._slideCount;
      },
      onChange: function () {
        if (_this._dots) this.updatePagination(clsPrefix + '__pagination--btn');
        if (_this._sliderIndex) _this._sliderIndex.innerHTML = this.currentSlide + 1 + ' / ' + _this._slideCount;
      }
    });

    carousel.setBaseClasses(clsPrefix + '__list', clsPrefix + '__item', clsPrefix + '__item-inner');
    if (_this._dots === 'true') carousel.setPagination(clsPrefix + '__pagination', clsPrefix + '__pagination--btn');
    //carousel.setHoverPause(_this._time);

    if (_this._prev && _this._next) {
      _this._prev.addEventListener('click', () => carousel.prev());
      _this._next.addEventListener('click', () => carousel.next());
    }
    // keyboard navigation
    /*document.addEventListener('keydown', (e) => {
      // left key
      if (e.keyCode === 37) {
        carousel.prev()
      }
      // right key
      else if (e.keyCode === 39) {
        carousel.next()
      }
    });*/

    // onResize
    window._change = window._change || {};
    window._change.resize = function () {
      carousel.setBaseClasses(clsPrefix + '__list', clsPrefix + '__item', clsPrefix + '__item-inner');
      if (_this._dots) {
        carousel.setPagination(clsPrefix + '__pagination', clsPrefix + '__pagination--btn');
        carousel.updatePagination(clsPrefix + '__pagination--btn');
      }
    };
    window.addEventListener('resize', window._change.resize);
  }

};
