export class lazyImg {
  constructor(el, config = {}) {
    if (!this._setVars(el)) return;
    this._setEvents(config);
  }

  _setVars(el) {
    let _this = this;

    _this._images = typeof el === 'string' ? document.body.querySelectorAll(el) : el;
    if (!this._images.length) return false;

    return true;
  }

  _setEvents(config) {
    let _this = this, imageCount = _this._images.length, observer;

    _this._rwd = isRWD(config.rwd || 680);

    observer = new IntersectionObserver(onIntersection, {
      root: config.root || null,
      rootMargin: config.rootMargin || '0px',
      threshold: config.threshold || [0.0]
    });

    let i = 0;
    for (i; i < _this._images.length; i++) !_this._images[i].classList.contains('loaded') && observer.observe(_this._images[i]);

    function onIntersection(el) {
      let i = 0;
      0 === imageCount && observer.disconnect();
      for (i; i < el.length; i++) 0 < el[i].intersectionRatio && (imageCount--, observer.unobserve(el[i].target), _this._preloadImage(el[i].target, i, config));
    }

    function disconnect() {
      observer && observer.disconnect()
    }
  }

  // IE11 require promise polyfill
  /*_fetchImage(el) {
    return new Promise((dataSrc, log) => {
      let newImg = new Image;
      newImg.src = el, newImg.onload = dataSrc, newImg.onerror = log
    })
  }*/

  _preloadImage(el, i, config) {
    let bgSrc = this._rwd && el.dataset.mob ? el.dataset.mob : el.dataset.url,
      imgSrc = this._rwd && el.dataset.mob ? el.dataset.mob : el.dataset.src,
      dataSrc = imgSrc || bgSrc;

    return lazyImg._applyImage(el, dataSrc, i, config);

    /*try {
      if (el.dataset.tpl) return lazyImg.tpl_render(el, i, config);
      return dataSrc ? this._fetchImage(dataSrc).then(() => {
        lazyImg._applyImage(el, dataSrc, i, config)
      }) : void 0
    } catch (e) {
      return lazyImg._applyImage(el, dataSrc, i, config);
    }*/
  }

  static tpl_render(el, i, config) {
    let tpl = render(el, el.firstElementChild, {parse: false});
    const src = tpl.firstElementChild.dataset.src;

    if (src && src.indexOf('youtube') > -1) {
      const mov_id = get_param('v', src), height = tpl.firstElementChild.dataset.height || '85%';
      tpl = render(el, `<iframe class="row h100 abs left0 top0" src="https://www.youtube-nocookie.com/embed/${mov_id}?rel=0&iv_load_policy=3?rel=0&controls=1&showinfo=0&enablejsapi=1&origin=${window.location.href}"></iframe>`);
      tpl.style.height = height;
    } else {
      el.appendChild(tpl);
      tpl = el.lastElementChild;
    }

    if (el.dataset.wow) lazyImg.wow(tpl);
    tpl.dataset.src ? tpl.src = tpl.dataset.src : lazyImg._applyBackground(el, tpl.dataset.url);
    if (config.cb) config.cb(el, i);
  }

  static _applyBackground(el, src) {
    if (getExt(src) === 'mp4') {
      return render(el, `<video src="${src}" ${el.dataset.params || ''} class="${el.dataset.class || ''}" style="${el.dataset.style || ''}"></video>`);
    }

    let pos, size, repeat;
    el.dataset.pos ? pos = el.dataset.pos : pos = '50% 50%';
    el.dataset.size ? size = el.dataset.size : size = 'cover';
    el.dataset.repeat ? repeat = el.dataset.repeat : repeat = 'no-repeat';

    setStyle(el, {
      backgroundImage: `url(${src})`,
      backgroundPosition: `${pos}`,
      backgroundSize: `${size}`,
      backgroundRepeat: `${repeat}`,
      maxHeight: '100%'
    });
  }

  static _applyImage(el, src, i, config) {
    el.dataset.wow ? lazyImg.wow(el) : el.dataset.nofade ? el.classList.add('loaded') : el.className += ' fin loaded';

    config.cb && config.cb(el, i);
    el instanceof HTMLImageElement ? el.src = src : lazyImg._applyBackground(el, src, config);
  }
}
